import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import { h } from 'vue'

const Layout = () => import('@/views/Layout')
const Home = () => import('@/views/home')
const SubCategory = () => import('@/views/category/sub')
const Goods = () => import('@/views/goods/index')
const NewsDetail = () => import('@/views/news/components/news-detail')
// 路由规则
const routes = [
  // 一级路由布局容器
  {
    path: '/',
    component: Layout,
    children: [
      { path: '/', component: Home },
      { path: '/category',   component: () =>import("@/views/category/sub.vue") },
      { path: '/category/sub/:id', component: SubCategory },
      { path: '/product/:id', component: Goods },
      { path: '/news/detail/:newsId', component: NewsDetail },
      {
        path: "/link",
        component: () => import("@/views/link/index.vue"),
        children: [
          {
            path: "/link/:flag",
            component: () =>import("@/views/link/components/link-about.vue"),
          }
        ],
      },
      {
        path: "/techplat",
        component: () => import("@/views/techplat/index.vue"),
      },
      {
        path: "/news",
        component: () => import("@/views/news/index.vue"),
        children: [
          {
            path: "/news/:id",
            component: () =>import("@/views/news/components/news-list.vue"),
          }
        ],
      },
      {
        path: "/productadv",
        component: () => import("@/views/productadv/index.vue"),
      },
      {
        path: "/solution",
        component: () => import("@/views/solution/index.vue"),
      },
      {
        path: "/case",
        component: () => import("@/views/case/index.vue"),
      },
      {
        path: "/cooperation",
        component: () => import("@/views/cooperation/index.vue"),
      },
      {
        path: "/technique",
        component: () => import("@/views/technique/index.vue"),
      },
      {
        path: "/solutions",
        component: () => import("@/views/solutions/index.vue"),
      },
      {
        path: "/test",
        component: () => import("@/views/test/index.vue"),
      }
    ]
  },
]

// vue2.0 new VueRouter({}) 创建路由实例
// vue3.0 createRouter({}) 创建路由实例
const router = createRouter({
  // 使用hash的路由模式
  history: createWebHashHistory(),
  routes,
  // 每次切换路由的时候滚动到页面顶部
  scrollBehavior () {
    // vue2.0  x  y  控制
    // vue3.0  left  top 控制
    return { left: 0, top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // 需要登录的路由：地址是以 /member 开头
  // const { profile } = store.state.user
  // if (!profile.token && to.path.startsWith('/member')) {
  //   return next('/login?redirectUrl=' + encodeURIComponent(to.fullPath))
  // }
  // next()

  // 根据屏幕分辨率执行跳转逻辑
  const screenWidth = window.screen.width;
    if (screenWidth <= 768) {
      // 如果分辨率小于等于768，跳转到域名A
      //服务器配置
      window.location.href = 'http://aiot.ibossay.com/h5';
    } 
 
    next(); // 继续执行路由跳转
 
});

export default router
