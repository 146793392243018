
// 默认9个顶级分类
export const topCategory = [
  ]

// 默认9个顶级分类
export const topMenu = [
  '首页',
  '产品中心',
  '典型案例',
  '市场优势',
  '技术平台',
  '联系我们',
  '新闻中心'
]
export const orderStatus = [
  
]

export const cancelReason = [
 
]
